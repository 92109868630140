import { Configuration, InteractionType, LogLevel, RedirectRequest } from '@azure/msal-browser';
import { config } from './environment';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { logDebug, logError, logInfo, logWarning } from './logging/writeLog';

// Production config
let clientId = '6099a8dd-953d-4ff3-a207-b1a7d2de42f9';
let tenantId = '8e61d5fe-7749-4e76-88ee-6d8799ae8143';
let redirectUri = 'https://outageadmin.sites.dteenergy.com';

if (!config.isProd) {
    // Test config
    clientId = '2e44bd5f-c251-405a-8f6f-c87761836932';
    tenantId = '8e61d5fe-7749-4e76-88ee-6d8799ae8143';
    if (typeof window !== 'undefined') {
        redirectUri = 'https://' + window?.location?.hostname;
    }
}

// TODO: separate config for other environments

// Map different trace levels to app insight logging functions
const traceLevels: Record<
    number,
    (appInsights: ReactPlugin, text: string, properties?: Record<string, unknown>) => void
> = {};
traceLevels[LogLevel.Error] = logError;
traceLevels[LogLevel.Warning] = logWarning;
traceLevels[LogLevel.Info] = logInfo;
traceLevels[LogLevel.Verbose] = logDebug;

export function getMsalConfig(appInsights: ReactPlugin): Configuration {
    // Config object to be passed to Msal on creation
    const msalConfig: Configuration = {
        auth: {
            clientId: clientId,
            authority: 'https://login.microsoftonline.com/' + tenantId,
            redirectUri: redirectUri,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false,
        },
    };

    msalConfig.system = {
        ...msalConfig.system,
        loggerOptions: {
            // By default, log all errors
            logLevel: LogLevel.Error,
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }

                let logger = traceLevels[level];
                if (!logger) {
                    logger = console.log;
                }
                logger(appInsights, message);
            },
        },
    };

    // Uncomment for more details tracing for troubleshooting
    // msalConfig.system.loggerOptions.logLevel = LogLevel.Trace;

    return msalConfig;
}

const defaultLoginRequest: RedirectRequest = {
    scopes: ['openid'],
    extraQueryParameters: { domain_hint: 'dteenergy.com' },
};
export function getRedirectRequest(): RedirectRequest {
    const request: RedirectRequest = {
        ...defaultLoginRequest,

        // Come back to where we are
        redirectStartPage: window?.location?.href,
    };

    return request;
}

// Which type of login we want to use as a fallback
export function getLoginMethod(): InteractionType {
    return InteractionType.Redirect;
}
