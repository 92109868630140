import { flattenDeep, ListOfRecursiveArraysOrValues } from 'lodash';

export function getArray<T>(values: T | ListOfRecursiveArraysOrValues<T>): T[] {
    if (values === undefined) {
        return [];
    }

    let valueArray;

    if (Array.isArray(values)) {
        valueArray = values;
    } else {
        valueArray = [values];
    }

    return flattenDeep(valueArray);
}

type MergeInput<T> = T | ListOfRecursiveArraysOrValues<T>;

export function mergeArrays<T>(...arrays: MergeInput<T>[]): T[] {
    const merged = arrays.reduce<T[]>((acc, current) => {
        return [...acc, ...getArray(current)];
    }, []);

    return merged;
}
